/**
 * Created by ssh on 2018/12/19
 * description: axios请求全局配置
 **/
import qs from 'qs'
import axios from 'axios'
import config from './config'

const service = axios.create(config);

const apiBaseUrl = process.env.VUE_APP_APIURL;
// const apiBaseUrl = 'https://service.iclass30.com';

// 请求拦截
service.interceptors.request.use((config) => {
    if (config.method === 'post') {
        config.data = qs.stringify(config.data);
    }
    return config;

}, (error) => {
    return Promise.reject(error);
});

// 返回状态判断
service.interceptors.response.use((res) => {
    //判断接口返回类型是否是字符串
    if (typeof (res.data) == 'string') {
        return Promise.resolve(res.data);
    }
    let _data = '';
    switch (res.data.code) {
        case 1:
            _data = res.data;
            break;
        case -1:
            console.error(`请求失败: ${res.config.url} ${JSON.stringify(res.config.params)}`);
            console.error(`失败原因: ${res.data.msg}`);
            _data = Promise.reject(res.data);
            alert(res.data.msg);
            break;
        case 1001:
            _data = res.data.code;
            break;
        case -2:
            _data = res.data.code;
            break;
        default:
            _data = Promise.reject(res.data);
            break;
    }
    return _data;
}, (error) => {
    // 404等问题可以在这里处理
    switch (error.response.status) {
        case 400:
            error.message = '请求错误';
            break;

        case 401:
            error.message = '未授权，请登录';
            break;

        case 402:
            error.message = '登录过期，请重新登录';
            break;

        case 403:
            error.message = '拒绝访问';
            break;

        case 404:
            error.message = `请求地址出错: ${error.response.config.url}`;
            break;

        case 408:
            error.message = '请求超时';
            break;

        case 500:
            error.message = '服务器内部错误';
            break;

        case 501:
            error.message = '服务未实现';
            break;

        case 502:
            error.message = '网关错误';
            break;

        case 503:
            error.message = '服务不可用';
            break;

        case 504:
            error.message = '网关超时';
            break;

        case 505:
            error.message = 'HTTP版本不受支持';
            break;
        default:
            error.message = '服务不可用';
            break;
    }
    console.error(error.message);
    return Promise.reject(error);
});

// 通用方法
export const POST = (url, params, baseUrl = apiBaseUrl) => {
    url = baseUrl + url;
    return service({
        method: 'post',
        url,
        data: params
    })
}

export const GET = (url, params, baseUrl = apiBaseUrl) => {
    url = baseUrl + url;
    return service({
        method: 'get',
        url,
        params
    })
}

export const DELETE = (url, params, baseUrl = apiBaseUrl) => {
    url = baseUrl + url;
    return service({
        method: 'delete',
        url,
        params
    })
}

// 用于mock拦截请求
export function FETCH (url, params) {
    // return new Promise((resolve, reject) => {
    //     axios.post(url, params)
    //         .then(response => {
    //             resolve(response.data);
    //         })
    //         .catch((error) => {
    //             reject(error);
    //         })
    // })
    return axios.post(url, qs.stringify(params)).then(res => res.data)
}
