/**
 * Created by sshu on 2020/5/14
 */
import * as API from './index'

let proxy = process.env.NODE_ENV === 'development' ? '' : '/api';

// 根据code获取腾讯智慧校园用户信息
export const findUserInfoByCode = (params) => {
    return API.GET('/userMgr/tencent/getUserInfoByCode', params)
};

// 通过腾讯智慧校园用户id获取同步用户信息
export const findLoginUserInfoByThirdId = (params) => {
    return API.GET('/userMgr/tencent/getLoginUserInfoByThirdId', params)
};

// 通过code获取品课的同步用户信息
export const pinKeUserLogin = (params) => {
    return API.GET('/userMgr/baselogin/pinKeUserLogin', params)
};

// 乐智用户登录
export const leZhiUserLogin = (params) => {
    return API.GET('/userMgr/baselogin/leZhiUserLogin', params)
};

// 东山一中用户登录
export const donShanUserLogin = (params) => {
    return API.GET('/userMgr/baselogin/dsyzSsoLogin', params)
};

// 贵州教育云用户登录
export const gzjyyUserLogin = (params) => {
    return API.GET('/userMgr/baselogin/gzjyyUserLogin', params)
};

// 曲靖一中用户登录
export const lgUserLoginAPI = (params) => {
    return API.GET('/userMgr/baselogin/lgUserLogin', params)
};
// 获取本系统的用户信息
export const getLoginUserInfoAPI = (params) => {
    return API.GET('/userMgr/baselogin/getLoginUserInfo', params)
};
//协小业务中台用户登录
export const xhxxUserLoginAPI = (params) => {
    return API.GET('/userMgr/baselogin/xhxxUserLogin', params)
};
//宸邦用户登录
export const chenBangUserLoginAPI = (params) => {
    return API.GET('/userMgr/baselogin/chenBangUserLogin', params)
};
//一起用户登录
export const getUserByYqTicketAPI = (params) => {
    return API.GET('/userMgr/baselogin/getUserByYqTicket', params)
};
//宁夏教育资源公共平台
export const nxjyUserLoginAPI = (params) => {
    return API.GET('/userMgr/baselogin/nxjyUserLogin', params)
};
//湘湖未来学校用户登录
export const xhwlLoginAPI = (params) => {
    return API.GET('/userMgr/baselogin/xhwlLoginByCode', params)
};
//上海教育根据授权码登录
export const shEduUserLoginAPI = (params) => {
    return API.GET('/userMgr/baselogin/shEduUserLogin', params)
};
 
//莞教通登录
export const wjtEduUserLoginAPI = (params) => {
    return API.GET('/userMgr/baselogin/wjtEduUserLogin', params)
};
 
//上虞登录
export const getUserIdByTokenAPI = (params) => {
    return API.GET('/sso/login/getUserIdByToken', params,'https://openapi.iclass30.com')
};
 
 
 


